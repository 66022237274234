import React from 'react';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Paths } from './Shared/Paths';
import FormDescription from './Components/FormDescription/FormDescription';
import SubmitSuccess from './Components/FormSuccess/FormSuccess';
import PrivateRoute from './Components/PrivateRoute';
import PatientInfo from './Pages/PatientInfo/PatientInfo';
import ChooseForm from './Pages/ChooseForm/ChooseForm';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';
import Hesper from './Pages/Hesper/Hesper';
import Pfa from './Pages/Pfa/Pfa';
import './App.scss';

Amplify.configure(awsconfig);

function App() {
  return (
    <AmplifyAuthenticator
      usernameAlias="email"
      style={{
        '--amplify-primary-color': '#828282',
        '--amplify-primary-tint': '#828282',
        '--amplify-primary-shade': '#4A5568',
        '--amplify-primary-contrast': '#fff',
        '--border-radius': '4px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <AmplifySignIn
        headerText="Welcome to Avetis"
        slot="sign-in"
        usernameAlias="email"
      />
      <div className="app-wrapper">
        <Router>
          <PrivateRoute
            exact
            path={Paths.patientInfo}
            component={PatientInfo}
          />
          <PrivateRoute exact path={Paths.chooseForm} component={ChooseForm} />
          <PrivateRoute exact path={Paths.hesperForm} component={Hesper} />
          <PrivateRoute exact path={Paths.pfaForm} component={Pfa} />
          <PrivateRoute
            exact
            path={Paths.submitSuccess}
            component={SubmitSuccess}
          />
          <PrivateRoute
            exact
            path={Paths.FormDescription}
            component={FormDescription}
          />
        </Router>
      </div>
    </AmplifyAuthenticator>
  );
}

export default App;
