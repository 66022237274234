/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:48178079-d762-48b7-b94f-58fcd215df45",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xuTPkQ5Ur",
    "aws_user_pools_web_client_id": "5b45qj16ehct0e5lnubrr30g8m",
    "oauth": {}
};


export default awsmobile;
