import React, { useEffect } from 'react';
import { PatientInfoQuestions } from '../../Shared/FormsQuestions';
import { DatePicker } from 'antd';
import moment from 'moment';
import './PatientInfo.scss';

function DatePick({ addToAnswers }) {
  const onDateChange = date => {
    date &&
      addToAnswers({
        id: PatientInfoQuestions.date.questionConceptName,
        questionConceptName: PatientInfoQuestions.date.questionConceptName,
        answerValue: date.format('YYYY-MM-DD').toString()
      });
  };

  useEffect(() => {
    onDateChange(moment());
  }, []);

  return (
    <div className="date-picker-wrapper">
      <h6 className="date-picker-title">Date *</h6>
      <DatePicker
        placeholder="Select date"
        className="date-picker"
        format={'DD/MM/YYYY'}
        onChange={date => onDateChange(date)}
        disabledDate={current => current && current > moment().endOf('day')}
        defaultValue={moment()}
      />
    </div>
  );
}

export default DatePick;
