import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { Input } from 'antd';
import './PfaQuestionsWrapper.scss';

const { TextArea } = Input;

const PfaQuestionsWrapper = ({
  questionConceptName,
  answers,
  title,
  required,
  ...props
}) => {
  const [checkedAnswersIds, setCheckedAnswersIds] = useState([]);

  const addToAnswers = answer => {
    props.addToAnswers({ ...answer, questionConceptName });
  };

  const removeFromAnswers = answer => {
    props.removeFromAnswers({ ...answer, questionConceptName });
  };

  const onAnswerChecked = answer => {
    setCheckedAnswersIds([...checkedAnswersIds, answer.id]);
    addToAnswers(answer);
  };

  const onAnswerUnchecked = answer => {
    setCheckedAnswersIds(checkedAnswersIds.filter(id => id !== answer.id));
    removeFromAnswers(answer);
  };

  function onAnswerTextAreaChanged(value, answer) {
    answer.answerValue = value;
    addToAnswers(answer);
  }

  return (
    <div className="pfa-questions-wrapper">
      <p>
        Check the boxes corresponding to difficulties the survivor is
        experiencing.
      </p>

      <p>
        {title}
        {required && <span> *</span>}
      </p>

      {answers.map(answer => {
        const extraProps = answer.textarea
          ? { checked: answer.answerValue }
          : {};

        return (
          <div key={answer.id} className="label-wrapper">
            <div className={answer.textarea && 'notEvent'}>
              <Checkbox
                id={answer.id}
                defaultChecked={checkedAnswersIds.find(id => id === answer.id)}
                onChange={e =>
                  e.target.checked
                    ? onAnswerChecked(answer)
                    : onAnswerUnchecked(answer)
                }
                {...extraProps}
              >
                {answer.description}
              </Checkbox>
            </div>
            {answer.textarea && (
              <TextArea
                className="pfa-other-answer-textarea"
                autoSize
                onChange={e => onAnswerTextAreaChanged(e.target.value, answer)}
                value={answer.answerValue}
                placeholder="Other"
                maxLength={120}
                showCount
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PfaQuestionsWrapper;
