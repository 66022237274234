import React, { useState } from 'react';
import { FormsIds, YesNoAnswers } from '../../Shared/Shared';
import { useDeepCompareEffect } from '../../Utils/UseDeepCompareEffect';
import { HesperQuestions } from '../../Shared/FormsQuestions';
import { Radio, Button } from 'antd';
import { submitForm } from '../../Store/actions/form';
import { connect } from 'react-redux';
import { Paths } from '../../Shared/Paths';
import { Link } from 'react-router-dom';
import './Hesper.scss';

const HesperQuestionsForm = props => {
  const [formFinished, setFormFinished] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleQuestionChange = (value, question) => {
    if (questionsAnswered.find(q => q.id === question.id)) return;

    setQuestionsAnswered([
      ...questionsAnswered,
      {
        id: question.id,
        questionConceptName: question.questionConceptName,
        answerValue: value
      }
    ]);
  };

  const submitForm = () => {
    const formSubmission = { formId: FormsIds.Hesper };
    formSubmission.answers = [
      ...props.patientInfoAnswers,
      ...questionsAnswered.map(question => ({
        questionConceptName: question.questionConceptName,
        answerValue: question.answerValue
      }))
    ];

    props.submitForm(formSubmission);
  };

  useDeepCompareEffect(() => {
    setFormFinished(HesperQuestions.length === questionsAnswered.length);

    props.setProgress(
      Math.trunc((questionsAnswered.length / HesperQuestions.length) * 100)
    );
  }, [questionsAnswered.length]);

  const next = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const prev = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  function shouldProceedNext() {
    return (
      currentQuestionIndex < HesperQuestions.length - 1 &&
      questionsAnswered.length > currentQuestionIndex
    );
  }

  return (
    <div>
      <div className="hsper-form-wrapper  fadeIn_animated">
        {HesperQuestions.map((question, index) => (
          <div
            className="hesper-question-wrapper fadeIn_animated"
            key={index}
            style={index === currentQuestionIndex ? {} : { display: 'none' }}
          >
            <h6 className="hesper-question-title fadeIn_animated">
              {question.questionTitle}
            </h6>

            <div className="steps-content fadeIn_animated">
              {question.question}
            </div>

            <Radio.Group
              className="hesper-answers-radio-btn fadeIn_animated"
              onChange={e => {
                handleQuestionChange(e.target.value.value, question);
                currentQuestionIndex < HesperQuestions.length - 1 && next();
              }}
            >
              <Radio value={YesNoAnswers.yes}>{YesNoAnswers.yes.label}</Radio>
              <Radio value={YesNoAnswers.no}>{YesNoAnswers.no.label}</Radio>
              <Radio value={YesNoAnswers.decline}>
                {YesNoAnswers.decline.label}
              </Radio>
              <Radio value={YesNoAnswers.notAvailable}>
                {YesNoAnswers.notAvailable.label}
              </Radio>
            </Radio.Group>
          </div>
        ))}

        <div className="steps-action fadeIn_animated">
          {currentQuestionIndex > 0 && (
            <Button
              className="hesper-next-btn fadeIn_animated"
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}

          {currentQuestionIndex < HesperQuestions.length - 1 && (
            <Button
              className={
                shouldProceedNext(currentQuestionIndex, questionsAnswered)
                  ? 'hesper-next-btn fadeIn_animated'
                  : 'hesper-next-btn-disabled fadeIn_animated'
              }
              disabled={
                !shouldProceedNext(currentQuestionIndex, questionsAnswered)
              }
              onClick={() => next()}
            >
              Next
            </Button>
          )}
        </div>
      </div>

      {formFinished && (
        <div className="hesper-submit-btn-wrapper">
          <Link to={Paths.submitSuccess}>
            <Button
              className="hesper-submit-btn fadeIn_animated"
              onClick={submitForm}
            >
              Submit
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ form }) => ({
  patientInfoAnswers: form.patientInfoAnswers || []
});

const mapDispatchToProps = dispatch => ({
  submitForm: formSubmission => dispatch(submitForm(formSubmission))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HesperQuestionsForm);
