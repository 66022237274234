import React from 'react';
import { Paths } from '../../Shared/Paths';
import { Link } from 'react-router-dom';
import HesperFormHead from '../FormHead/FormHead';
import './FormDescription.scss';

function FormDescription(props) {
  return (
    <>
      <HesperFormHead isFormDescription />
      <div className="form-description-wrapper fadeIn_animated">
        <h3>{`welcome to 
              ${
                props.location?.state?.referrer === Paths.pfaForm
                  ? 'PFA'
                  : 'HESPER'
              } form`}</h3>
        <p>
          {props.location?.state?.referrer === Paths.pfaForm
            ? `The Psychological First Aid (PFA) Field
            Operations provides an assessment of
            needs to give guidance on using the
            Psychological First Aid intervention.`
            : `The Humanitarian Emergency Settings
              Perceived Needs Scale (HESPER) provides a
              quick way of assessing the needs of people
              affected by humanitarian emergencies,
              such as war or major natural disaster`}
        </p>
        <div className="get-started-link">
          <Link to={props.location?.state?.referrer || Paths.chooseForm}>
            get started
          </Link>
        </div>
      </div>
    </>
  );
}

export default FormDescription;
