import React from 'react';
import { PfaQuestions } from '../../Shared/FormsQuestions';
import { Input } from 'antd';
import './PfaQuestionsWrapper.scss';

const { TextArea } = Input;

function ReferralOtherInfo({ addToAnswers }) {
  const onTextChange = value => {
    addToAnswers({
      id: PfaQuestions.referralOtherInfo.questionConceptName,
      questionConceptName: PfaQuestions.referralOtherInfo.questionConceptName,
      answerValue: value
    });
  };

  return (
    <div className="pfa-note-wrapper">
      <p>
        Please make note of any other information that might be helpful in
        making a referral.
      </p>
      <TextArea
        className="pfa-note-answer-textarea"
        autoSize
        onChange={e => onTextChange(e.target.value)}
        placeholder="Additional information"
        maxLength={100}
        showCount
      />
    </div>
  );
}

export default ReferralOtherInfo;
