import React from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { Paths } from '../../Shared/Paths';
import { Link } from 'react-router-dom';
import ContactUsModal from '../../Components/ContactUsModal/ContactUsModal';
import './ChooseForm.scss';

function ChooseForm() {
  return (
    <div className="choose-form-wrapper">
      <div className="choose-form-head">
        <ContactUsModal />
        <div>
          <div className="fake-logout-btn">LOG OUT</div>
          <div className="logout-btn-wrapper">
            <AmplifySignOut className="logout-btn" />
          </div>
        </div>
      </div>
      <div>
        <h6 className="choose-form-title">
          {`What form would you
            like to complete?`}
        </h6>
        <div className="choose-form-btn-wrapper">
          <div className="choose-form-btn-container">
            <Link
              className="choose-form-btn"
              to={{
                pathname: Paths.patientInfo,
                state: { referrer: Paths.hesperForm }
              }}
            >
              HESPER
            </Link>
            <p className="choose-form-btn-description" tabIndex="0">
              {`The Humanitarian Emergency Settings
                Perceived Needs Scale (HESPER) provides a
                quick way of assessing the needs of people
                affected by humanitarian emergencies, such
                as war or major natural disaster`}
            </p>
          </div>
          <div className="choose-form-btn-container">
            <Link
              className="choose-form-btn"
              to={{
                pathname: Paths.patientInfo,
                state: { referrer: Paths.pfaForm }
              }}
            >
              PFA Field Operations
            </Link>
            <p className="choose-form-btn-description" tabIndex="0">
              {`The Psychological First Aid (PFA) Field
                Operations provides an assessment of needs
                to give guidance on using the Psychological
                First Aid intervention.`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseForm;
